<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Toolbar class="p-mb-4">
<!--          <template v-slot:left>-->
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Offers') }}</div>
            <AddNewButton :disabled="!allDataLoaded" :label="$t('Add')" @click="addNewItem"/>
          </template>
<!--          <template v-slot:right>-->
          <template #end>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

<!--        <div>{{ httpClient.endPoint }}</div>-->
        <Spinner v-if="isLoading"></Spinner>
<!--        <div v-else-if="!isLoading && !items || !items.length" class="p-text-center p-m-2 p-p-6">{{ $t('No data available') }}</div>-->
        <div v-else-if="!isLoading && !items || !items.length" class="no-data-block">{{ $t('No data available') }}</div>
<!--        <template v-else-if="!isLoading && !$store.state.tabletLayout">-->
<!--        <template v-else-if="!isLoading">-->
        <template v-else>
          <DesktopDataTable v-for="(date, index) of dates"
                            v-show="datedItems[index].length"
                            :key="index"
                            class="datatable"
                            :dataTableIsLoading="dataTableIsLoading"
                            :tableName="parseDate(date)"
                            :itemsArrayIsEmpty="itemsArrayIsEmpty"
                            :sort="sort"
                            :hightlightTable="isTableHightlight(date)"
                            :showSearchInput="false"
                            :actionButtonsCount="actionButtonsCount"
                            :headers="[
                // {name: 'time', title: 'Time', sortable: false},
                {name: 'created_at', title: '', sortable: false},
                // {name: 'created_at', title: 'Time',  width: '60px', minWidth: '60px', sortable: false},
                {name: 'number', title: 'Number', sortable: false},
                // {name: '', title: '', width: '45px', minWidth: '45px', sortable: false}, //Source
                {name: 'customer', title: 'Customer',  width: '16%', sortable: false},
                {name: 'offer', title: 'Offer data',  width: '18%', sortable: false},
                {name: 'time', title: 'Time', width: '6%', sortable: false},
                {name: 'car', title: 'Car data', width: '15%', sortable: false},
                // {name: 'area_id', title: 'Area', width: '6%', sortable: false},
                {name: 'responsible', title: 'Res.',  width: '4%', sortable: false},
                {name: 'masters', title: 'Masters', width: '8%', sortable: false},
                {name: 'amount', title: 'Sum', width: '6%', sortable: false},
                {name: 'state', title: 'State', width: '10%', sortable: false},
                {name: '', title: 'Order', width: '6%', sortable: false},
                {name: 'valid_until', title: 'Valid until', width: '6%', sortable: false},
                {name: '', title: '', width: '3%', sortable: false},
                {name: '', title: '', width: '4%', sortable: false},
                {name: '', title: '', width: '3%', sortable: false},
            ]">
            <template v-slot:body>
              <template v-for="item of datedItems[index]" :key="item.id">
                <tr :class="{'updated-item': isItemUpdated(item.id)}">
<!--                  <td>{{ formatTime(item.created_at) }}</td>-->
<!--                  <td>-->
<!--                    <div>{{ item.number }}</div>-->
<!--                    <div v-if="item.parentOrder?.number" class="p-pt-1" style="font-size: 0.85em">-->
<!--                      <router-link :to="{ path: `/orders/${item.parentOrder.number}` }" target="_blank">-->
<!--                        <i style="font-size: 0.90em" class="ti-hummer p-mr-1"></i>-->
<!--                        <span>{{ item.parentOrder.number }}</span>-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <span v-if="item.source"></span>-->
<!--                    <i v-else class="ti-minus"></i>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <i v-if="item.source" :class="{'ti-world': item.source === 5}"></i>-->
<!--                    <i v-else class="ti-minus"></i>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    {{ item.time }}-->
<!--                  </td>-->
                  <td>
                    {{ formatTime(item.created_at) }}
                  </td>
                  <td>
                    <div>{{ item.number }}</div>
                    <div v-if="item.parentOffer?.number" class="p-pt-1" style="font-size: 0.85em">
                      <router-link :to="{ path: '/offers', query: { search: item.parentOffer.number } }" target="_blank">
                        <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>
                        <span>{{ item.parentOffer.number }}</span>
                      </router-link>
                    </div>
                    <div v-else-if="item.parentOrder?.number" class="p-pt-1" style="font-size: 0.85em">
                      <router-link :to="{ path: `/orders/${item.parentOrder.number}` }" target="_blank">
                        <i style="font-size: 0.90em" class="ti-hummer p-mr-1"></i>
                        <span>{{ item.parentOrder.number }}</span>
                      </router-link>
                    </div>
                  </td>
                  <td>
                    <TableCustomerData :customer="item.customer" @change-customer-status="changeCustomerStatus"/>
                  </td>
                  <td>
<!--                    <span>-->
<!--                      <a href="#" @click.prevent="toggleShowPositionTable(item)" class="table-title-link">-->
<!--                        <i style="font-size: 0.9em" class="pi" :class="{'pi-minus': item.showPositionsTable, 'pi-plus':!item.showPositionsTable}"></i>-->
<!--&lt;!&ndash;                        <i style="font-size: 0.9em;" class="pi pi-plus" v-else></i>&ndash;&gt;-->
<!--                        <span class="text">{{ item.number }}</span>-->
<!--                      </a>-->
<!--                    </span>-->
                    <div>
                      <a href="#" @click.prevent="toggleShowPositionTable(item)">
                        <i style="font-size: 0.9em" class="p-mr-1 pi" :class="item.showPositionsTable ? 'pi-minus': 'pi-plus'"></i>
                        <span class="text">{{ item.name || item.number }}</span>
                      </a>
<!--                      <div v-if="item.parentOffer?.number" style="font-size: 0.85em;">-->
<!--                        <span class="p-mr-1">From:</span>-->
<!--                        <router-link :to="{ path: '/offers', query: { search: item.parentOffer.number } }" target="_blank">-->
<!--                          <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>-->
<!--                          <span>{{ item.parentOffer.number }}</span>-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                      <div v-else-if="item.parentOrder?.number" style="font-size: 0.85em;">-->
<!--                        <span class="p-mr-1">From:</span>-->
<!--                        <router-link :to="{ path: `/orders/${item.parentOrder.number}` }" target="_blank">-->
<!--                          <i style="font-size: 0.90em" class="ti-hummer"></i>-->
<!--                          <span>{{ item.parentOrder.number }}</span>-->
<!--                        </router-link>-->
<!--                      </div>-->
                    </div>
                    <div class="table-small-text">
<!--                      <span>{{ $t('Created') }}: </span>-->
                      <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
                      <span v-if="item.creator">
<!--                          <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
                          <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}</span>
                          <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
                        </span>
                    </div>
                  </td>
                  <td>
                    <span v-if="item.time && +item.time !== 0">{{ item.time }} {{ $t('h') }}.</span>
                    <i v-else class="ti-minus"></i>
                  </td>
<!--                  <td>-->
<!--                    <div v-if="item.car?.make?.logo_path" class="p-mr-2">-->
<!--                      <img style="max-width: 40px; max-height: 40px; display: block" :src="settings.apiEndPoint + settings.pathToCarLogosDir + item.car.make.logo_path">-->
<!--                    </div>-->
<!--                  </td>-->
                  <td>
                    <span v-if="item.car" >
                      <router-link :to="{ path: `/cars/${item.car.id}` }" target="_blank">
                        <i class="ti-car p-mr-1"></i>
                        <span>{{ item.car.make?.name ?? '' }} {{ item.car.model?.name ?? '' }}</span>
<!--                        <span class="p-mr-1" v-if="item.car.make">{{ item.car.make.name }}</span>-->
<!--                        <span class="p-mr-1" v-if="item.car.model">{{ item.car.model.name }}</span>-->
                      </router-link>
                      <div class="table-small-text p-d-flex p-ai-center">
                        <div>
                          <span>{{ item.car.plate_number }}</span>
                          <span v-if="item.car.year">, {{ item.car.year }}</span>
                        </div>
                        <CarDataLink :plateNumber="item.car.plate_number" :showOnlyIcon="true"/>
                      </div>
                    </span>
                    <i v-else class="ti-minus"></i>
                  </td>
<!--                  <td>-->
<!--                    <span v-if="item.area?.name">{{ item.area.name }}</span>-->
<!--                    <i v-else class="ti-minus"></i>-->
<!--                  </td>-->
<!--                  <td class="avatar-td">-->
<!--                    <span v-if="item.responsible" class="avatar-wrapper">-->
<!--&lt;!&ndash;                      <router-link :to="{ path: `/employees/${item.responsible.id}` }" target="_blank">&ndash;&gt;-->
<!--&lt;!&ndash;                      <img v-if="item.responsible.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + item.responsible.profile_img + '.jpg'" :alt="item.responsible.first_name + ' ' + item.responsible.last_name" :title="item.responsible.first_name + ' ' + item.responsible.last_name">&ndash;&gt;-->
<!--                      <img v-if="item.responsible.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + item.responsible.profile_img" :alt="item.responsible.first_name + ' ' + item.responsible.last_name" :title="item.responsible.first_name + ' ' + item.responsible.last_name">-->
<!--                      <img v-else :src="settings.apiEndPoint + settings.pathToAvatarsDir + 'avatar_placeholder.png'" :alt="item.responsible.first_name + ' ' + item.responsible.last_name" :title="item.responsible.first_name + ' ' + item.responsible.last_name">-->
<!--                      &lt;!&ndash;                      </router-link>&ndash;&gt;-->
<!--                    </span>-->
<!--                  </td>-->
                  <td class="avatar-td">
                    <AvatarsCell :responsible="item.responsible"/>
                  </td>
                  <td class="avatar-td">
                    <AvatarsCell :masters="item.masters"/>
                  </td>
                  <td>
                    <span>{{ formatCurrency(item.grand_total) }}</span>
                  </td>
                  <td>
                    <OfferStateButton :item="item"/>
                  </td>
                  <td>
                    <Button :disabled="(disableEditButton && isItemUpdated(item.id)) || !allDataLoaded || dataTableIsLoading" v-if="item.state === 3" @click="createOrder(item)" v-tooltip.top="'Create order'" class="p-button-outlined p-button-sm p-d-flex p-ai-center p-jc-center" style="width: 40px;">
                      <i class="ti-plus"></i>
                      <i class="ti-hummer"></i>
                    </Button>
                    <span v-else-if="item.childOrder?.number">
                      <router-link :to="{ path: `/orders/${item.childOrder.number}` }" target="_blank">{{ item.childOrder.number }}</router-link>
                    </span>
                  </td>
                  <td>
                    <span v-if="!!item.valid_until && item.state !== 5 && item.state !== 7" :class="{'warning-color': countOverdueDays(item.valid_until) <= 0}">{{ formatDay(item.valid_until) }}</span>
                    <i v-else class="ti-minus"></i>
                  </td>
                  <td>
                    <GetOfferPdfButtonSM :item="item" :disableEditButton="disableEditButton" :showUpdatedItemPdfSpinner="showUpdatedItemPdfSpinner" :isItemUpdated="isItemUpdated(item.id)"/>
                  </td>
                  <td>
                    <Button v-if="item.file_path && userEmailAccounts"
                            :disabled="!userHaveEmailAddress(item.customer) || (disableEditButton && isItemUpdated(item.id)) || !allDataLoaded || dataTableIsLoading"
                            @click="createOfferEmail(item)"
                            v-tooltip.top="'Send message'"
                            class="p-button-outlined p-button-sm p-d-flex p-ai-center p-jc-center"
                            :class="{'send-message-btn--disabled': !userHaveEmailAddress(item.customer)}"
                            style="width: 40px;">
                      <i class="ti-email"></i>
                    </Button>
                  </td>
                  <td>
                    <OfferHistoryCell :item="item"/>
                  </td>
                  <td>
<!--                    <div class="table__actions-column-data">-->
                    <div class="p-d-flex">
<!--                      <router-link :to="{ path: `/customers/${item.id}` }" target="_blank"><ViewButton/></router-link>-->
                      <EditButton :disabled="(disableEditButton && isItemUpdated(item.id)) || !allDataLoaded || dataTableIsLoading" @click="editItem(item)" />
                      <DeleteButton :disabled="!allDataLoaded || dataTableIsLoading" @click="confirmItemDelete({id: item.id, name: item.name})"
                                    v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                    </div>
                  </td>
                </tr>

                <tr v-show="item.showPositionsTable" class="table-opened-info">
                  <td colspan="20" style="padding:0!important;">
                    <PositionsTable :item="item" :isSpoilerTable="true" :isOffer="true" @update-items="updateItems"/>
                  </td>
                </tr>
              </template>
            </template>
          </DesktopDataTable>
        </template>

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

        <OfferModal :visible="itemModal"
                    :item="item"
                    :masters="masters"
                    :responsibles="responsibles"
                    :taxes="taxes"
                    :services="services"
                    :suppliers="suppliers"
                    :warehouseItems="warehouseItems"
                    @update-items="updateItems"
                    @update-item="updateOffer"
                    @update-customer="updateCustomer"
                    @close="closeItemModal">
        </OfferModal>

        <OrderModal :visible="orderModal"
                    :fromOffersModule="true"
                    :item="orderData"
                    :masters="masters"
                    :responsibles="responsibles"
                    :taxes="taxes"
                    :services="services"
                    :suppliers="suppliers"
                    :areas="areas"
                    :warehouseItems="warehouseItems"
                    @update-item="updateOffer"
                    @close="closeOrderModal">
        </OrderModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>

        <EmailModal :visible="emailModal"
                    :userEmailAccounts="userEmailAccounts"
                    :item="emailData"
                    @update-items="updateItems"
                    @update-item="updateOffer"
                    @close="closeEmailModal">
        </EmailModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import OfferModal from '@/pages/offers/components/OfferModal'
import OrderModal from '@/pages/orders/components/OrderModal'
import OfferStateButton from '@/pages/offers/components/OfferStateButton'
import formatMixins from '@/mixins/formatMixins'
import PositionsTable from '@/components/PositionsTable'
import httpMixins from '@/mixins/httpMixins'
import calculateMixins from '@/mixins/calculateMixins'
import datatableMixins from '@/mixins/datatableMixins'
import TableCustomerData from "@/components/TableCustomerData";
// import SmallPdfButton from "@/components/SmallPdfButton";
// import createOfferEmailCell from "@/components/createOfferEmailCell";
import EmailMessages from "@/translations/EmailMessages";
import settings from "@/settings";
import EmailModal from "@/pages/messages/components/EmailModal";
import OfferHistoryCell from "@/pages/offers/components/OfferHistoryCell";
// import CreateOfferEmailButton from "@/pages/offers/components/CreateOfferEmailButton";
import GetOfferPdfButtonSM from "@/pages/offers/components/GetOfferPdfButtonSM";
import SmsMessages from "@/translations/SmsMessages";
import AvatarsCell from "@/components/AvatarsCell";
import CarDataLink from "@/pages/cars/components/CarDataLink";

export default {
  mixins: [ formatMixins, httpMixins, calculateMixins, datatableMixins ],
  components: { OfferModal, OrderModal, OfferStateButton, PositionsTable, TableCustomerData, EmailModal, OfferHistoryCell, GetOfferPdfButtonSM, AvatarsCell,CarDataLink },
	data() {
		return {
      showUpdatedItemPdfSpinner: false,
      emailModal: false,
      emailData: {},
      userEmailAccounts: null,
      openedItemsIds: [],
      // disableEditButton: false,
      // masters: null,
      // responsibles: null,
      // taxes: null,
      // services: null,
      // suppliers: null,
      // warehouseItems: null,
      // areas: null,
      overlayPanel: [],
      // isLoading: true,
      dates: [],
      datedItems: [],
      showTime: {},
      fieldsExpand: 'responsible,customer,customer.address,customer.customerStatus,creator,masters,positions,positions.history,consumable,car,comments_history,state_history,childOrder,parentOrder',
      apiLink: 'offer/get-active-offers',
      routePath: '/offers',
      sort: {
        column: 'id',
        direction: '-',
      },
      orderModal: false,
      orderData: null,
		}
	},
  watch: {
    '$store.state.updateOffersPage'(boolean) {
      if (boolean) {
        this.getItems()
      }
    },
    // '$route'() {
    //   this.checkRouteQuery()
    //   if (this.$route.path === '/login' || this.$route.path !== '/offers') {
    //     return false
    //   }
    //
    //   if(!this.isLoading) {
    //     if (this.$route.query.search) {
    //       this.getItems(false)
    //     } else {
    //       this.getItems()
    //     }
    //   }
    // },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        // if (this.itemModal) {
        //   this.closeItemModal()
        // }
        if (this.orderModal) {
          this.closeOrderModal()
        }
        if (this.emailModal) {
          this.closeEmailModal()
        }
        // if (this.confirmItemDeleteModal) {
        //   this.closeConfirmItemDeleteModal()
        // }
      }
    },
    '$store.state.user.emailAccounts'() {
      this.generateUserEmailAccounts()
    },
    // 'pagination.perPage'() {
    //   if (this.$route.path === '/offers') {
    //     localStorage.setItem('offersPerPage',this.pagination.perPage)
    //   }
    // }
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()

    this.getModalData()

    this.generateUserEmailAccounts()

    // this.getMasters()
    // this.getResponsibles()
    // this.getTaxes()
    // this.getServices()
    // this.getSuppliers()
    // this.getAreas()
    // this.getWarehouseItems()
  },
  methods: {
    updateCustomer(user, orderId) {
      const offersWithSameCustomer = this.items?.filter(i => i.customer?.id === user.id && i.id !== orderId)

      if (offersWithSameCustomer?.length) {
        offersWithSameCustomer.forEach(o => o.customer = user)
        this.constructOffersTable()
      }
    },
    userHaveEmailAddress(user) {
      if (!user) return false
      if (user.type === this.constants.userTypes.legal_entity && user.company_name) {
        return !!(user.invoice_email || user.company_email)
      } else {
        return !!user.email
      }
    },
    createOfferEmail(offer) {
      const offerMessage = EmailMessages.offerEstimated[offer.customer?.language] ?? EmailMessages.offerEstimated[settings.defaultLanguage]
      const smsMessage = SmsMessages.offerEstimated[offer.customer?.language] ?? SmsMessages.offerEstimated[settings.defaultLanguage]
      const containsActivePositions = !!offer.positions?.find(p => p.status)

      this.emailData = {
        isOffer: true,
        offer_id: offer.id,
        subject: offerMessage?.subject + offer.number,
        greeting: offerMessage?.greeting,
        offer_text: containsActivePositions ? offerMessage?.text : '<br>',
        sent_at: offer.sent_at,
        file_path: offer.file_path,
        branch_id: offer.branch_id,
        user_id: offer.user_id,
        containsActivePositions
        // positionsLength: offer.positions?.length
        // offer_link_text: settings.useOfferRegistrationLink && offerMessage ? offerMessage.linkText  : null,
      }

      if (offer.customer) {
        if (offer.customer.type === this.constants.userTypes.legal_entity && offer.customer.company_name && offer.customer.company_email) {
          this.emailData.to_email = offer.customer.company_email
        } else if (offer.customer.email) {
          this.emailData.to_email = offer.customer.email
        }
      }

      if (containsActivePositions && settings.useOfferRegistrationLink && offer.hash && offer.pin) {
        const offerLink = settings.host + settings.pathToOffer + offer.hash

        if (offerMessage) {
          // if (offer.hash) {
            // const offerLink = settings.pathToOffer + offer.hash
          this.emailData.offer_link_text = offerMessage.linkText + offerLink

          // }
          // if (offer.pin) {
          this.emailData.offer_pin_text = `<b>${offerMessage.pinText}${offer.pin}</b>`
          // }
        }

        if (!!offer.customer?.is_sms_notification_enabled && settings.useSmsService && smsMessage) {
          this.emailData.sms_text = `${smsMessage.text}${offerLink} ${smsMessage.pinText}${offer.pin}`
        }
      }

      this.emailModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeEmailModal() {
      this.emailData = {}
      this.emailModal = false
      // this.submitted = false
      this.$store.commit('toggleFirstLayer', false)
    },
    // generateUserEmailAccounts() {
    //   if (!this.$store.state.user.emailAccounts?.length) return false
    //   this.userEmailAccounts = this.$store.state.user.emailAccounts.map(account => (
    //       {id: account.accountData?.id, username: account.accountData?.username, sender_name: account.accountData?.sender_name, signature: account.signature, is_default_for_offers: account.accountData?.is_default_for_offers, is_default_for_invoices: account.accountData?.is_default_for_invoices}
    //   ))
    // },
    async updateOffer(id) {
      if (!id) return false
      // this.sendUpdateOfferWebSocket(id)

      let foundItem = this.items?.find(item => item.id === id)
      if (foundItem) {
        const indexOfFoundItem = this.items.indexOf(foundItem)
        if (indexOfFoundItem !== -1) {
          const itemData = await this.getOfferById(id)
          if (itemData) {
            this.items[indexOfFoundItem] = itemData
            this.updatedItemId = id
            this.changeItemsWithSameUserOrCar(itemData)
            this.constructOffersTable()
          }
        }
      }
      // await this.getNewOffersCount()
    },
    changeItemsWithSameUserOrCar(itemData) {
      const ordersWithSameCustomer = this.items?.filter(i => i.customer?.id === itemData.customer?.id && i.id !== itemData.id)
      if (ordersWithSameCustomer?.length) {
        ordersWithSameCustomer.forEach(o => o.customer = itemData.customer)
      }
      const ordersWithSameCar = this.items?.filter(i => i.car?.id === itemData.car?.id && i.id !== itemData.id)
      if (ordersWithSameCar?.length) {
        ordersWithSameCar.forEach(o => o.car = itemData.car)
      }
    },
    async getOfferById(id) {
      // console.log('getOfferById')
      try {
        const { data, status } = await httpClient.post(`offer/get-by-id?expand=${this.fieldsExpand}`, { id })
        if (status === 200 && data?.success && data.item) {
          return data.item
        } else {
          return null
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.$store.commit('changeUpdatedOrderId', null)
      }
    },
    createOrder(offer) {
      this.orderData = {
        ...offer,
        positions: offer.positions?.filter(pos => pos.status === 1)?.map(p => ({ ...p, isNew: true, tax_id: null })),
        id: null,
        responsible: null,
        state: 1,
        comments_history: null,
        history: null,
        customerDisabled: true,
        carDisabled: true,
        offerId: offer.id,
      }

      if (this.itemModal) {
        this.itemModal = false
      }
      this.orderModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeOrderModal() {
      this.orderModal = false
      // this.submitted = false
      this.$store.commit('toggleFirstLayer', false)
    },
    // toggleShowPositionTable(item, positionsEmpty = false) {
    //   if (positionsEmpty) return false
    //   item.showPositionsTable = !item.showPositionsTable
    //   this.openedItemsIds = this.items.filter(item => item.showPositionsTable)?.map(i => { return i.id })
    // },
    // updateItems(id, clearItem = false) {
    //   this.disableEditButton = true
    //   if (id) {
    //     this.updatedItemId = id
    //   }
    //   if (clearItem) {
    //     this.item = {}
    //     // this.submitted = false
    //   }
    //   this.getItems(false)
    // },
    async getItems(showSpinner = false) {
      // console.log('get offers')
      this.dataTableIsLoading = true
      this.disableEditButton = true
      // this.items = []
      if (showSpinner) {
        this.isLoading = true
      }
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient({
          // const { data } = await httpClient({
          url: this.apiLink,
          // method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          // data: {}
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']

          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
          this.constructOffersTable()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
        this.showUpdatedItemPdfSpinner = false
        this.$store.commit('updateOffersPage', false)
        // this.getNewOffersCount()
      }
    },
    constructOffersTable() {
      if (!this.items?.length) return
      let dates = []
      this.items.forEach(item => {
        const date = new Date(item.created_at * 1000)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).length === 1 ? '0' + String(date.getMonth() + 1) : date.getMonth() + 1
        const day = String(date.getDate()).length === 1 ? '0' + String(date.getDate()) : date.getDate()

        const fullDate = `${year}-${month}-${day}`
        if (dates.indexOf(fullDate) === -1) {
          dates.push(fullDate)
        }
      })

      if (this.openedItemsIds.length) {
        this.items.forEach(item => {
          if (this.openedItemsIds.indexOf(item.id) !== -1) {
            item.showPositionsTable = true
          }
        })
      }

      this.dates = dates.sort((a, b) => +new Date(b) - +new Date(a))

      let i = 0
      // for (let date of this.dates) {
      //   this.datedItems[i] = this.items.filter(item => {
      //     return new Date(item.created_at * 1000).getDate() === Number(date.slice(8, 10)) &&
      //            new Date(item.created_at * 1000).getMonth() + 1 === Number(date.slice(5, 7)) &&
      //            new Date(item.created_at * 1000).getFullYear() === Number(date.slice(0, 4))
      //   }).sort((a, b) => b.id - a.id)
      //   i++
      // }
      for (let date of this.dates) {
        this.datedItems[i] = this.items.filter(item => {
          const itemDate = new Date(item.created_at * 1000)
          return +itemDate.getFullYear() === Number(date.substr(0, 4)) &&
              +itemDate.getMonth() + 1 === Number(date.substr(5, 2)) &&
              +itemDate.getDate() === Number(date.substr(8, 2))
        }).sort((a, b) => b.created_at - a.created_at)
        i++
      }
    },
    parseDate(date) {
      const parsedDate = new Date(date)
      const parsedDateYear = parsedDate.getFullYear()
      const parsedDateMonth = parsedDate.getMonth()
      const parsedDateDay = parsedDate.getDate()
      const parsedDay = new Date(parsedDateYear, parsedDateMonth, parsedDateDay)

      const todayDate = new Date()
      const todayYear = todayDate.getFullYear()
      const todayMonth = todayDate.getMonth()
      const todayDay = todayDate.getDate()
      const today = new Date(todayYear, todayMonth, todayDay)

      const yesterday = new Date(todayYear, todayMonth, todayDay - 1)
      const tomorrow = new Date(todayYear, todayMonth, todayDay + 1)
      let day = ''

      if (+parsedDay === +today) {
        day = ' - ' + this.$t('Today')
      } else if (+parsedDay === +yesterday) {
        day = ' - ' + this.$t('Yesterday')
      } else if (+parsedDay === +tomorrow) {
        day = ' - ' +  this.$t('Tomorrow')
      }
      // if (parsedDate.getDate() === today.getDate() &&
      //     parsedDate.getMonth() === today.getMonth() &&
      //     parsedDate.getFullYear() === today.getFullYear()) {
      //       day = ' - ' + this.$t('Today')
      // } else if (parsedDate.getDate() + 1 === today.getDate() &&
      //     parsedDate.getMonth() === today.getMonth() &&
      //     parsedDate.getFullYear() === today.getFullYear()) {
      //       day = ' - ' + this.$t('Yesterday')
      // } else if (parsedDate.getDate() - 1 === today.getDate() &&
      //     parsedDate.getMonth() === today.getMonth() &&
      //     parsedDate.getFullYear() === today.getFullYear()) {
      //       day = ' - ' +  this.$t('Tomorrow')
      // }

      // return parsedDate.toLocaleString(this.$store.state.branchData.locale, { timeZone: this.$store.state.branchData.timezone, year: 'numeric', month: 'numeric', day: 'numeric' }) + ' ' + day
      return this.formatDay(parsedDate / 1000) + ' ' + day
    },
    async deleteItem() {
      console.log('delete offer')

      // try {
      //   const { status } = await httpClient.delete(`user/${this.itemToDelete.id}`)
      //   if (status === 204) {
      //     this.updatedItemId = null
      //
      //     this.searchData = ''
      //     this.$router.push({
      //       route: this.$route.path,
      //       query: {
      //         ...this.$route.query,
      //         search: '',
      //       }
      //     })
      //
      //     this.getItems()
      //     this.closeConfirmItemDeleteModal()
      //   }
      // } catch(err) {
      //   console.log(err)
      // }
    },
    isTableHightlight(date) {
      const now = new Date()
      date = new Date(date)
      return date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.masters && this.responsibles && this.taxes && this.services && this.suppliers && this.areas && this.warehouseItems)
    },
    actionButtonsCount() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin) {
        return 2
      } else {
        return 1
      }
    },
    itemToDeleteData() {
      return 'AAAA!'
    },
  }
}
</script>

<style scoped lang="scss">
.no-data-block {
  padding: 12vh 0;
  //height: 100%;
  text-align: center;
}

.send-message-btn--disabled {
  opacity: 0.5;
}
</style>
